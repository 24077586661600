import Box from "@mui/material/Box";
import DataGridTemplate from "../../../DataGridTemplate/DataGridTemplate";
import ProductionOverviewColumns from "./ProductionOverviewColumns";

const ProductionOverviewDataGrid = (props) => {
  const { tableData, isLoading } = props;
  const columns = ProductionOverviewColumns();
  const sxBox = {
    height: 115,
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  return (
    <div>
      <Box sx={sxBox}>
        {tableData && (
          <DataGridTemplate
            columns={columns}
            uniqueRows={tableData}
            isLoading={isLoading}
            CustomToolbar="None" 
            density="compact"
            size="tiny"
            hideFooter={true}
          />
        )}
      </Box>
    </div>
  );
};

export default ProductionOverviewDataGrid;
