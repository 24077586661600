import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import classes from "../../DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../DataGridTemplate/StripedDataGridTemplate";
import { setFilteredTotals } from "../../../store/actions/production/filteredProductionTotals";
import PlanChangesSummaryColumns from "./PlanChangesSummaryColumns";
import PlanChangesSummaryData from "./PlanChangesSummaryData";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import DataGridNoResults from "../../DataGridTemplate/DataGridNoResults";

const PlanChangeSummary = () => {
  const data = useSelector((state) => state.planChange.production);
  const filterModel = useSelector((state) => state.planChange.filterModel);
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  let results = useMemo(() => [], []);
  const [uniqueRows, setUniqueRows] = useState([]);
  const columns = PlanChangesSummaryColumns();
  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: 640,
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  const StripedDataGridNoResults = () => {
    return <DataGridNoResults columns={columns} uniqueRows={uniqueRows} />;
  };

  useEffect(() => {
    let agents = [];
    let years = [];
    let carriers = [];

    const filterByYear = () => {
      data.forEach((item) => {
        years.push(new Date(item.effectivedate).getFullYear());
      });
    };

    const filteredByAgent = () => {
      data.forEach((item) => {
        agents.push(item.agent_id);
      });
    };

    const filteredByCarrier = (id, year) => {
      data.forEach((item) => {
        if (
          item.agent_id === id &&
          new Date(item.effectivedate).getFullYear() === year
        ) {
          carriers.push(item.carrier_id);
        }
      });
    };

    const distinctListOf = (arr) => {
      return [...new Set(arr)];
    };

    const uniqueListOfYears = () => {
      filterByYear();
      const results = distinctListOf(years);
      return results;
    };

    const uniqueListOfIds = () => {
      filteredByAgent();
      const results = distinctListOf(agents);
      return results;
    };

    const uniqueListOfCarriers = (id, year) => {
      filteredByCarrier(id, year);
      const results = distinctListOf(carriers);
      return results;
    };

    const processData = () => {
      const years = uniqueListOfYears();
      const Ids = uniqueListOfIds();
      years.forEach((year) => {
        Ids.forEach((id) => {
          let carriers = uniqueListOfCarriers(id, year);
          carriers.forEach((carrier) => {
            let dataResults = data.filter(
              (row) => row.agent_id === id && row.carrier_id === carrier
            );
            if (dataResults.length > 0) {
              let agentName = dataResults[0].agentname;
              let carrierName = dataResults[0].carrier_name;
              let ma_production = dataResults.reduce(
                (sum, row) => sum + parseInt(row.ma_production),
                0
              );
              let medsupp_production = dataResults.reduce(
                (sum, row) => sum + parseInt(row.medsupp_production),
                0
              );
              let pdp_production = dataResults.reduce(
                (sum, row) => sum + parseInt(row.pdp_production),
                0
              );
              let planYear = new Date(dataResults[0].effectivedate)
                .getFullYear()
                .toString();
              results.push({
                agentName: agentName,
                carrierName: carrierName,
                ma_production: ma_production,
                medsupp_production: medsupp_production,
                pdp_production: pdp_production,
                planYear: planYear,
              });
            }
          });
        });
      });
    };

    if (data) {
      processData();
      if (results.length > 0) {
        setUniqueRows(PlanChangesSummaryData({ data: results }));
      }
    }
  }, [data, results, setUniqueRows]);

  const MemoizedDataGrid = memo(StripedDataGrid);

  if (uniqueRows.length === 0) {
    return (
      <div className={classes.myClass}>
        <Box sx={sxBox}>
          <StripedDataGridNoResults />
        </Box>
      </div>
    );
  } else {
    return (
      <div className={classes.myClass}>
        <Box sx={sxBox}>
          <MemoizedDataGrid
            apiRef={apiRef}
            slots={{ toolbar: CustomToolbar }}
            loading={false}
            rows={uniqueRows.sort((a, b) =>
              (a.agentname || "").localeCompare(b.agentname || "")
            )}
            columns={columns}
            pagination={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
            disableRowSelectionOnClick
            initialState={{
              filter: { filterModel: filterModel },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            onStateChange={() => {
              const filteredSortedRowIds =
                gridFilteredSortedRowIdsSelector(apiRef);
              const filteredSortedRows = filteredSortedRowIds.map((id) =>
                apiRef.current.getRow(id)
              );
              const maTotal = filteredSortedRows.reduce(
                (sum, row) => sum + parseInt(row.ma_production),
                0
              );
              const msTotal = filteredSortedRows.reduce(
                (sum, row) => sum + parseInt(row.medsupp_production),
                0
              );
              const pdpTotal = filteredSortedRows.reduce(
                (sum, row) => sum + parseInt(row.pdp_production),
                0
              );

              dispatch(
                setFilteredTotals({
                  maTotal: maTotal,
                  msTotal: msTotal,
                  pdpTotal: pdpTotal,
                })
              );
            }}
          />
        </Box>
      </div>
    );
  }
};

export default PlanChangeSummary;
