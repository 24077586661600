import {
  SET_PRODUCTION_STATISTICS_FOR_AGENCY,
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR,
  CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY,
  PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
  CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
  CLEAR_FILTERED_PRODUCTION_TOTALS,
  SET_PRODUCTION_FILTER_MODEL,
  SET_FILTERED_PRODUCTION_TOTALS,
  SET_PRODUCTION_ERROR,
  SET_PRODUCTION_GRID_DATA,
  CLEAR_PRODUCTION_GRID_DATA,
  PRODUCTION_GRID_DATA_LOADING,
  SET_PRODUCTION_GRID_DATA_ERROR,
} from "../../constants/action-types";

const initState = {
  dataSummary: [],
  currentYear: 0,
  isLoading: false,
  FetchingProductionError: undefined,
  filteredProductionTotals: [],
  topAgents: [],
  filterModel: {
    items: [],
  },
};

export const productionStatisticsForAgency = (
  state = initState,
  { type, payload }
) => {
  switch (type) {
    case SET_PRODUCTION_STATISTICS_FOR_AGENCY:
      return {
        ...state,
        FetchingProductionError: undefined,
        dataSummary: payload,
        currentYear: payload.currentYear,
        availablePlanYears: payload.availablePlanYears,
        topAgents: payload.topAgents,
      };
    case SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR:
      return { ...state, loadingError: payload };
    case CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY:
      return { ...state, dataSummary: [] };
    case PRODUCTION_STATISTICS_FOR_AGENCY_LOADING:
      return { ...state, isLoading: payload };
    case SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    case CLEAR_FILTERED_PRODUCTION_TOTALS:
      return {
        ...state,
        filteredProductionTotals: [],
      };
    case SET_PRODUCTION_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case SET_FILTERED_PRODUCTION_TOTALS:
      return { ...state, filteredProductionTotals: payload };
    case SET_PRODUCTION_ERROR:
      return { ...state, FetchingProductionError: payload };
    case SET_PRODUCTION_GRID_DATA:
      return { ...state, gridData: payload };
    case CLEAR_PRODUCTION_GRID_DATA:
      return { ...state, gridData: [] };
    case PRODUCTION_GRID_DATA_LOADING:
      return { ...state, isFetchingGridData: payload };
    case SET_PRODUCTION_GRID_DATA_ERROR:
      return { ...state, FetchingProductionError: payload };
    default:
      return state;
  }
};
