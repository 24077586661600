import { useSelector } from "react-redux";
import LineChartHeader from "./LineChartHeader";
import LineChartBody from "./LineChartBody";

const LineChartComponent = () => {
  const { productionByCarrierAndQtr, productionSalesYear } = useSelector(
    (state) => state.productionLineGraph
  );

  return (
    <div>
      <div style={{ paddingBottom: "10px" }}>
        <LineChartHeader data={productionSalesYear} />
        <LineChartBody data={productionByCarrierAndQtr} />
      </div>
    </div>
  );
};

export default LineChartComponent;
