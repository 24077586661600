const ProductionStatisticsSummaryData = (props) => {
  const { data } = props;
  const uniqueRows = [];
  const dataRows = data?.map((row) => { 
    const id = row.agentId + row.carrierName + row.salesYear;  
    return {
      id: id,
      agentName: row.agentName,
      npn: row.npn,
      carrierName: row.carrierName,
      maTotal: row.agentCarrierMaGrandTotal,
      medsuppTotal: row.agentCarrierMedsuppGrandTotal,
      pdpTotal: row.agentCarrierPdpGrandTotal,
      salesYear: row.salesYear,
    };    
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  return uniqueRows;
};

export default ProductionStatisticsSummaryData;
