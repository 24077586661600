import {
  SET_PRODUCTION_STATISTICS_FOR_AGENCY,
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR,
  CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY,
  PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
  CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
  SET_FILTERED_PRODUCTION_TOTALS,
  CLEAR_FILTERED_PRODUCTION_TOTALS,
  SET_PRODUCTION_GRID_DATA,
  CLEAR_PRODUCTION_GRID_DATA,
  PRODUCTION_GRID_DATA_LOADING,
  SET_PRODUCTION_GRID_DATA_ERROR,
} from "../../constants/action-types";

import {
  getProductionStatisticsForAgency,
  getAvailablePlanYears,
} from "../../../services/GraphQl/BDSWebService";

export const setProductionStatisticsForAgency = (agencyId, year) => {
  return async (dispatch) => {
    dispatch({
      type: PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
      payload: true,
    });
    let data = await getProductionStatisticsForAgency(agencyId);
    const topAgents = [];
    let availablePlanYears = [];
    if (data) {
      if (!year) {
        year = data.reduce(
          (max, row) => (row.salesYear > max ? row.salesYear : max),
          0
        );
        sessionStorage.setItem("currentProductionYear", year);
      } else {
        sessionStorage.setItem("currentProductionYear", year);
      }
      let availablePlanYears = sessionStorage.getItem("availablePlanYears");
      if (!availablePlanYears) {
        availablePlanYears = await getAvailablePlanYears();
        sessionStorage.setItem("availablePlanYears", availablePlanYears);
      } else {
        availablePlanYears = availablePlanYears.split(",");
      }
    }

    let filteredData = data.filter((row) => row.salesYear === year);
    let ma = [];
    let medSupp = [];
    let agents = [];
    let maAgentName = "";
    let medSuppAgentName = "";
    let maTotal = 0;
    let medsuppTotal = 0;
    let pdpTotal = 0;

    const topMAAgent = () => {
      if (ma.length > 0) {
        const maxTotal = ma.reduce(
          (max, item) => (item.total > max.total ? item : max),
          ma[0]
        );
        return maxTotal.name;
      }
    };

    const topMedsuppAgent = () => {
      if (medSupp.length > 0) {
        const maxTotal = medSupp.reduce(
          (max, item) => (item.total > max.total ? item : max),
          medSupp[0]
        );
        return maxTotal.name;
      }
    };

    const filteredByAgent = () => {
      filteredData.forEach((item) => {
        agents.push(item.agentId);
      });
    };

    const distinctListOfAgentIds = (arr) => {
      return [...new Set(arr)];
    };

    const uniqueListOfIds = () => {
      filteredByAgent();
      const results = distinctListOfAgentIds(agents);
      return results;
    };

    const getNameAndTotalMA = (id) => {
      const data = filteredData.filter((row) => row.agentId === id);
      const name = data[0].agentName;
      let total = 0;
      data.forEach((item) => {
        total += item.agentCarrierMaGrandTotal;
      });
      return { name, total };
    };

    const getNameAndTotalMedSupp = (id) => {
      const data = filteredData.filter((row) => row.agentId === id);
      const name = data[0].agentName;
      let total = 0;
      data.forEach((item) => {
        total += item.agentCarrierMedsuppGrandTotal;
      });
      return { name, total };
    };

    const processData = () => {
      const Ids = uniqueListOfIds();
      Ids.forEach((id) => {
        ma.push(getNameAndTotalMA(id));
        medSupp.push(getNameAndTotalMedSupp(id));
      });
    };

    if (filteredData && year) {
      processData();
      maAgentName = topMAAgent();
      medSuppAgentName = topMedsuppAgent();
      maTotal = filteredData.reduce(
        (sum, row) => sum + parseInt(row.agentCarrierMaGrandTotal),
        0
      );
      medsuppTotal = filteredData.reduce(
        (sum, row) => sum + parseInt(row.agentCarrierMedsuppGrandTotal),
        0
      );
      pdpTotal = filteredData.reduce(
        (sum, row) => sum + parseInt(row.agentCarrierPdpGrandTotal),
        0
      );

      topAgents.push({
        maAgentName,
        medSuppAgentName,
        maTotal,
        medsuppTotal,
        pdpTotal,
      });

      sessionStorage.setItem(
        "topAgents",
        JSON.stringify({
          maAgentName: maAgentName,
          medSuppAgentName: medSuppAgentName,
          maTotal: maTotal,
          medsuppTotal: medsuppTotal,
          pdpTotal: pdpTotal,
        })
      );
    }

    dispatch({
      type: SET_PRODUCTION_STATISTICS_FOR_AGENCY,
      payload: data,
      dataSummary: data,
      currentYear: year,
      availablePlanYears: availablePlanYears,
      topAgents: topAgents,
    });

    dispatch({
      type: PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
      payload: false,
    });
  };
};

export const setProductionStatisticsForAgencyError = (error) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR,
    payload: error,
  };
};

export const clearProductionStatisticsForAgency = () => {
  return {
    type: CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY,
  };
};

export const setProductionStatisticsForAgencyLoading = (data) => {
  return {
    type: PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
    payload: data,
  };
};

export const setProductionStatisticsForAgencyFilterModel = (data) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
    payload: data,
  };
};

export const clearProductionStatisticsForAgencyFilterModel = () => {
  return {
    type: CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
  };
};

export const setFilteredProductionTotals = (data) => {
  const currentYear = sessionStorage.getItem("currentProductionYear");
  const results = data.filter((item) => item.salesYear === currentYear);
  return {
    type: SET_FILTERED_PRODUCTION_TOTALS,
    payload: results,
  };
};

export const clearFilteredProductionTotals = () => {
  return {
    type: CLEAR_FILTERED_PRODUCTION_TOTALS,
  };
};

export const setProductionGridData = (data) => {
  return {
    type: SET_PRODUCTION_GRID_DATA,
    payload: data,
  };
};

export const clearProductionGridData = () => {
  return {
    type: CLEAR_PRODUCTION_GRID_DATA,
  };
};

export const setProductionGridDataLoading = (data) => {
  return {
    type: PRODUCTION_GRID_DATA_LOADING,
    payload: data,
  };
};

export const setProductionGridDataError = (error) => {
  return {
    type: SET_PRODUCTION_GRID_DATA_ERROR,
    payload: error,
  };
};

export const getProductionStatisticsForAgencyAction = (agencyId) => {
  return async (dispatch) => {
    dispatch(setProductionStatisticsForAgencyLoading(true));
    try {
      dispatch(setProductionStatisticsForAgency(agencyId));
    } catch (error) {
      dispatch(setProductionStatisticsForAgencyError(error));
    } finally {
      dispatch(setProductionStatisticsForAgencyLoading(false));
    }
  };
};
