import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const ProductionStatisticsSummaryTotals = () => {
    const maTotal = useSelector((state) =>
      state.productionStatisticsForAgency.filteredProductionTotals.maTotal
    );
    const msTotal = useSelector((state) =>
       state.productionStatisticsForAgency.filteredProductionTotals.msTotal
    );
    const pdpTotal = useSelector((state) =>
        state.productionStatisticsForAgency.filteredProductionTotals.pdpTotal
    );  

    

    const items = [
      { label: "Medicare Advantage", total: maTotal },
      { label: "Medicare Supplement", total: msTotal },
      { label: "Prescription Drug", total: pdpTotal },
    ];
    const sxTypography = { color: "white" };
    return (
      <Typography sx={sxTypography}>
        {items.map((item, index) => (
          <Box key={index}>
            {item.label}: {item.total}
          </Box>
        ))}
      </Typography>
    );
  };

export default ProductionStatisticsSummaryTotals;