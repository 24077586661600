import { Button, ButtonGroup } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import history from "../../../utils/history";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setAgentListFilterModel } from "../../../store/actions/agents/agentsContractInfo";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const ProductionStatisticsSummaryColumns = () => {
  const dispatch = useDispatch();
  const navigate = useCallback((path) => {
    history.push(path);
  }, []);
  const handleClick = useCallback(
    (e, cellValue) => {
      dispatch(
        setAgentListFilterModel({
          items: [
            {
              field: "agentName",
              value: cellValue,
              operatorValue: GridLogicOperator.EQ,
            },
          ],
        })
      );
      navigate("/AgentList");
    },
    [dispatch, navigate]
  );

  const commonProps = {
    headerAlign: "center",
    align: "center",
    headerClassName: "super-app-theme--header",
    flex: 0,
  };

  const columns = [
    {
      field: "agentName",
      headerName: "Agency/Agent Name",
      width: 300,
      type: "string",
      renderCell: (params) => (
        <ButtonGroup variant="contained">
          <Button
            endIcon={<AccountCircle />}
            onClick={(event) => handleClick(event, params.value)}
            sx={{ width: "300px", flex: 1 }}
          >
            {params.value}
          </Button>
        </ButtonGroup>
      ),
    },
    {
      field: "npn",
      headerName: "NPN",
      width: 125,
      type: "string",
    },
    {
      field: "carrierName",
      headerName: "Carrier Name",
      width: 125,
      type: "string",
      flex: 1,
    },
    {
      field: "maTotal",
      headerName: "MA",
      width: 10,
      type: "number",
      flex: 1,
    },
    {
      field: "medsuppTotal",
      headerName: "MEDSUPP",
      width: 10,
      type: "number",
      flex: 1,
    },
    {
      field: "pdpTotal",
      headerName: "PDP",
      width: 10,
      type: "number",
      flex: 1,
    },
    {
      field: "salesYear",
      headerName: "Plan Year",
      width: 10,
      type: "string",
      flex: 1,
    },
  ];

  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default ProductionStatisticsSummaryColumns;
