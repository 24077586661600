import { AccountCircle } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { setAgentListFilterModel } from "../../../store/actions/agents/agentsContractInfo"
import history from "../../../utils/history";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

const PlanChangesSummaryColumns = () => {
    const dispatch = useDispatch();
const navigate = useCallback(
    (path) => {
      history.push(path);
    },
    []
  );
  const handleClick = useCallback(
    (e, cellValue) => {
      dispatch(
        setAgentListFilterModel({
          items: [
            {
              field: "agentName",
              value: cellValue,
              operatorValue: GridLogicOperator.EQ,
            },
          ],
        })
      );
      navigate("/AgentList");
    },
    [dispatch, navigate]
  );

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  flex: 0,
};

const columns = [
  {
    field: "agentName",
    headerName: "Agent Name",
    width: 300,
    type: "string",
    renderCell: (params) => {
      return (
        <ButtonGroup variant="contained">
          <Button
            endIcon={<AccountCircle />}
            onClick={(event) => handleClick(event, params.value)}
            sx={{ width: "300px", flex: 1 }}
          >
            {params.value}
          </Button>
        </ButtonGroup>
      );
    },
  },
  {
    field: "carrierName",
    headerName: "Carrier Name",
    width: 125,
    type: "string",
    flex: 1,
  },
  {
    field: "ma_production",
    headerName: "MA",
    width: 10,
    type: "number",
    flex: 1,
  },
  {
    field: "medsupp_production",
    headerName: "MEDSUPP",
    width: 10,
    type: "number",
    flex: 1,
  },
  {
    field: "pdp_production",
    headerName: "PDP",
    width: 10,
    type: "number",
    flex: 1,
  },
  {
    field: "planYear",
    headerName: "Plan Year",
    width: 10,
    type: "string",
    flex: 1,
  },
];

return columns.map((column) => ({ ...commonProps, ...column }));
}

export default PlanChangesSummaryColumns;

