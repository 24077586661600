import {
  SET_CURRENT_PRODUCTION,
  CLEAR_CURRENT_PRODUCTION,
  SET_PRODUCTION_ERROR,
  CURRENT_PRODUCTION_LOADING,
  SET_PRODUCTION_FILTER_MODEL,
  CLEAR_PRODUCTION_FILTER_MODEL,
  SET_PRODUCTION_GRID_DATA,
  CLEAR_PRODUCTION_GRID_DATA,
  PRODUCTION_GRID_DATA_LOADING,
  SET_PRODUCTION_GRID_DATA_ERROR,
} from "../../constants/action-types";

const initState = {
  gridData: [],
  isFetchingGridData: false,
  FetchingProductionError: undefined,
  isFetchingProductionList: false,
  production: [],
  filteredProductionTotals: { maTotal: 0, msTotal: 0, pdpTotal: 0 },
  filterModel: {
    items: [],
  },
};

export const currentProduction = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_PRODUCTION:
      return {
        ...state,
        FetchingProductionError: undefined,
        currentYear: payload.currentYear,
        availablePlanYears: payload.availablePlanYears,
        production: payload.production,
      };
    // case SET_FILTERED_PRODUCTION_TOTALS:
    //   return { ...state, filteredProductionTotals: payload };
    // case CLEAR_FILTERED_PRODUCTION_TOTALS:
    //   return {
    //     ...state,
    //     filteredProductionTotals: { maTotal: 0, msTotal: 0, pdpTotal: 0 },
    //   };
    case SET_PRODUCTION_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_PRODUCTION_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    case CLEAR_CURRENT_PRODUCTION:
      return payload;
    case CURRENT_PRODUCTION_LOADING:
      return { ...state, isFetchingProductionList: payload };
    case SET_PRODUCTION_ERROR:
      return { ...state, FetchingProductionError: payload };
    case SET_PRODUCTION_GRID_DATA:
      return { ...state, gridData: payload };
    case CLEAR_PRODUCTION_GRID_DATA:
      return { ...state, gridData: [] };
    case PRODUCTION_GRID_DATA_LOADING:
      return { ...state, isFetchingGridData: payload };
    case SET_PRODUCTION_GRID_DATA_ERROR:
      return { ...state, FetchingProductionError: payload };
    default:
      return state;
  }
};
