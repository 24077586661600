import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  cellClassName: "super-app-theme--cell",
  flex: 1,
};

const columns = [
  {
    field: "agentName",
    headerName: "Agent Name",
    width: 300,
    type: "string",
  },
  {
    field: "npn",
    headerName: "NPN",
    width: 100,
    type: "string",
  },
  {
    field: "planYear",
    headerName: "Plan Year",
    width: 100,
    type: "string",
  },
  {
    field: "carrierName",
    headerName: "Carrier Name",
    width: 225,
    type: "string",
  },
  {
    field: "isCorporation",
    headerName: "Is Corp",
    width: 125,
    type: "boolean",
    valueOptions: [true, false],
    renderCell: (params) => {
      return params.value ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "certified",
    headerName: "Is Certified",
    width: 125,
    type: "singleSelect",
    valueOptions: ["true", "false"],
    renderCell: (params) => {
      return params.value === "true" ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      );
    },
  },
  {
    ...commonProps,
    field: "isReady",
    headerName: "Is RTS",
    width: 125,
    type: "boolean",
    valueOptions: [true, false],
    renderCell: (params) => {
      return params.value ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "product",
    headerName: "Product",
    width: 200,
    type: "string",
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
    type: "string",
  },
];

const ReadyToSellColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default ReadyToSellColumns;
