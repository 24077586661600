import React, { Fragment, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Typography, Paper } from "@mui/material";
import ProductionOverviewDataGrid from "./ProductionOverviewDataGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    color: "#003760",
  },
  table: {
    padding: 10,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 34,
    width: 3,
    backgroundColor: "grey",
  },
  paper: (props) => ({
    padding: 10,
    margin: 10,
    width: props.isSmallScreen ? "100%" : "auto",
    height: 115,
  }),
}));

const ProductionOverview = (props) => {
  const { year } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isSmallScreen });
  let results = [];

  let ma_top = "",
    ma_total = 0,
    ms_top = "",
    ms_total = 0,
    productMA = "Medicare Advantage",
    productMS = "Medicare Supplement";
  const data = useSelector(
    (state) => state.productionStatisticsForAgency.dataSummary
  );

  const isLoading = useSelector(
    (state) => state.productionStatisticsForAgency.isLoading
  );

  const [maAgentName, setMaAgentName] = useState("");
  const [medSuppAgentName, setMedSuppAgentName] = useState("");

  const filteredData = data.filter((row) => row.salesYear === year);

  useEffect(() => {
    let agents = [];
    let ma = [];
    let medSupp = [];

    const topMAAgent = () => {
      if (ma.length > 0) {
        const maxTotal = ma.reduce(
          (max, item) => (item.total > max.total ? item : max),
          ma[0]
        );
        return maxTotal.name;
      }
    };
  
    const topMedsuppAgent = () => {
      if (medSupp.length > 0) {
        const maxTotal = medSupp.reduce(
          (max, item) => (item.total > max.total ? item : max),
          medSupp[0]
        );
        return maxTotal.name;
      }
    };

    const filteredByAgent = () => {
      filteredData.forEach((item) => {
        agents.push(item.agentId);
      });
    };

    const distinctListOfAgentIds = (arr) => {
      return [...new Set(arr)];
    };

    const uniqueListOfIds = () => {
      filteredByAgent();
      const results = distinctListOfAgentIds(agents);
      return results;
    };

    const getNameAndTotalMA = (id) => {
      const data = filteredData.filter((row) => row.agentId === id);
      const name = data[0].agentName;
      let total = 0;
      data.forEach((item) => {
        total += item.agentCarrierMaGrandTotal;
      });
      return { name, total };
    };

    const getNameAndTotalMedSupp = (id) => {
      const data = filteredData.filter((row) => row.agentId === id);
      const name = data[0].agentName;
      let total = 0;
      data.forEach((item) => {
        total += item.agentCarrierMedsuppGrandTotal;
      });
      return { name, total };
    };

    const processData = () => {
      const Ids = uniqueListOfIds();
      Ids.forEach((id) => {
        ma.push(getNameAndTotalMA(id));
        medSupp.push(getNameAndTotalMedSupp(id));
      });
    };

    if (data && year) {
      processData();
      setMaAgentName (topMAAgent());
      setMedSuppAgentName(topMedsuppAgent());
    }
  }, [data, year, filteredData, setMaAgentName, setMedSuppAgentName]);  

  if (data && year) {
    ma_top = maAgentName;
    ma_total = filteredData.reduce(
      (sum, row) => sum + parseInt(row.agentCarrierMaGrandTotal),
      0
    );
    ms_top = medSuppAgentName;
    ms_total = filteredData.reduce(
      (sum, row) => sum + parseInt(row.agentCarrierMedsuppGrandTotal),
      0
    );   
  }

  results.push(
    {
      id: 1,
      product: productMA,
      top: ma_top,
      total: ma_total,
    },
    {
      id: 2,
      product: productMS,
      top: ms_top,
      total: ms_total,
    }
  );

  return (
    <Fragment>
      <Typography color="#003760" gutterBottom variant="h5" component="h3">
        Production Overview
      </Typography>
      <Paper className={classes.paper}>
        <ProductionOverviewDataGrid tableData={results} isLoading={isLoading} />
      </Paper>
    </Fragment>
  );
};

export default ProductionOverview;