
const PlanChangesSummaryData = (props) => {
    const { data } = props;
    const uniqueRows = [];
    const dataRows = data.map((row) => {
      return {
        id:
          row.agentName +
          row.carrierName +
          row.planYear,
        agentName: row.agentName,
        carrierName: row.carrierName,
        ma_production: row.ma_production,
        medsupp_production: row.medsupp_production,
        pdp_production: row.pdp_production,
        planYear: row.planYear,
      };
    });
  
    dataRows.forEach((row) => {
      if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
        uniqueRows.push(row);
      }
    });

    return uniqueRows;
}

export default PlanChangesSummaryData;

