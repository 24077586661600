import { Button, ButtonGroup, Grid } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { grey, yellow } from "@mui/material/colors";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    select: {
      color: "#eee",
    },
    inputContainer: {
      backgroundColor: "currentColor",
      color: "#eee",
      fontWeight: "bold",
    },
    selectorInput: {},
    typoGraphy: {
      color: "#003760",
      fontSize: "calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontWeight: "bold",
    },
    iconButton: {
      display: "inline-block",
      width: "auto",
      margin: 0,
      padding: 0,
      background: "none",
      backgroundColor: "transparent",
      border: "none",
      flexGrow: 1,
    },
    fabYellow: {
      color: theme.palette.common.black,
      backgroundColor: yellow[300],
      "&:hover": {
        backgroundColor: yellow[600],
      },
      width: "40px", // Add this
      height: "40px", // Add this
    },
    disabledGray:{
      color: theme.palette.common.black,
      backgroundColor: grey[300],
      "&:hover": {
        backgroundColor: grey[600],
      },
      width: "40px", // Add this
      height: "40px", // Add this
    },
    iconImage: {
      width: "50%",
    },
    input: {
      width: "100%",
      marginLeft: 8,
      flexGrow: 1,
      fontSize: "calc(8px + (24 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      [theme.breakpoints.down("xs")]: {
        fontSize: "calc(24px + (48 - 24) * ((100vw - 250px) / (4000 - 250)))",
        lineHeight:
          "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      },
    },
    buttonIcon: {
      marginBlock: "auto",
    },
  };
});

const ButtonReset = (props) => {
  const {
    setCheckedCarrierValues,
    setCheckedLevelValues,
    setCheckedContractStatusValues,
    setAgencyId,
    type,
    disabled,
  } = props;
  const { classes } = useStyles();
  const onClickHandler = () => {
    if (type === "carrier") {
      setCheckedCarrierValues([]);
    }
    if (type === "level") {
      setCheckedLevelValues([]);
    }
    if (type === "contract") {
      setCheckedContractStatusValues([]);
    }
    if (type === "agencyGroup") {
      setAgencyId(sessionStorage.getItem("agencyId"));
    }
  };  
    return (
      <Grid
        item
        xs={1}
        style={{ m: 1, margin: "15px", alignContent: "center" }}
      >
        <ButtonGroup fullWidth data-tour="ad__resetAndDownload">
          <Button
            fullWidth
            onClick={onClickHandler}
            className={classes.fabYellow}
            disabled={disabled}
          >
            <Clear className={classes.buttonIcon} />
          </Button>
        </ButtonGroup>
      </Grid>
    );  
};

export default ButtonReset;
