import ProductionStatisticsSummaryMUI from "./ProductionStatisticsSummaryMUI";
import PlanChangeSummary from "../PlanChangesSummary/PlanChangesSummary";
import PageContainerWithMenu from "../../../containers/PageContainerWithMenu";
import ProductionStatisticsSummaryTotals from "./ProductionStatisticsSummaryTotals";
import PlainMenu from "../../PlainMenu";
import { Grid, Switch, Typography, Box } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

const ProductionStatisticsSummaryInfo = () => {
  const [productionStatisticsToggle, setProductionStatisticsToggle] =
      useState(true);
  const useStyles = makeStyles()((theme) => {
      return {
        switchContainer: {
          color: "rgb(0, 55, 96)",
          backgroundColor: "white",
          padding: "0px 12px",
          borderRadius: 5,
          marginBottom: 0,
          alignItems: "center",
          justifyContent: "center",
          marginRight: 0,
          width: "100%",
          "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "rgb(0, 55, 96)",
            opacity: 0.75,
          },
          "& .MuiSwitch-colorSecondary": {
            color: "rgb(0, 55, 96)",
          },
          "& .MuiSwitch-input": { display: "flex" },
        },
      };
    });
  
    const { classes } = useStyles();
  return (
    <PageContainerWithMenu
      data-tour="ad__home"
      type="productionSummary"
      HasMenu={true}
      menu={
        <PlainMenu
          title="Production Statistics Summary"
          submenu={
            <div style={{ margin: "0 10px 0 10px" }}>
              <Grid
                container
                alignItems="center"
                className={classes.switchContainer}
              >
                <Grid item>
                  <Typography variant="body1">Production Statistics Summary</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={!productionStatisticsToggle}
                    onChange={() =>
                      setProductionStatisticsToggle(!productionStatisticsToggle)
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">Plan Change Summary</Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProductionStatisticsSummaryTotals
                  productionStatisticsToggle={productionStatisticsToggle}
                />
              </Box>
              <br />
              <br />
            </div>
          }
        />
      }
      content={
        productionStatisticsToggle ? (
          <ProductionStatisticsSummaryMUI />
        ) : (
          <PlanChangeSummary />
        )
      }
    />
  );
};

export default ProductionStatisticsSummaryInfo;
