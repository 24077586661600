const ReadyToSellData = (props) => {
  const { data } = props;
  const uniqueIds = new Set();
  const uniqueRows = [];

  data?.forEach((row) => {
    const id =
      row.agentName +
      row.planYear +
      row.carrierName +
      row.isCorporation +
      row.isCertified +
      row.isReady +
      row.product +
      row.state;
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueRows.push({
        id,
        agentName: row.agentName,
        npn: row.npn,
        planYear: row.planYear,
        carrierName: row.carrierName,
        isCorporation: row.isCorporation,
        certified: row.certified,
        isReady: row.isReady,
        product: row.product,
        state: row.state,
      });
    }
  });

  return uniqueRows;
};

export default ReadyToSellData;
