import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import "./LandingPage.css";
import { getAgency } from "../store/actions/index";
import { setProductionStatisticsForAgency } from "../store/actions/production/productionStatisticsForAgency";
import { setPlanChange } from "../store/actions/plan-change/PlanChange";
import { setETLDates } from "../store/actions/production/productionActions";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import history from "../utils/history";
import { getProductionStatisticsForAgencyFullAction } from "../store/actions/production/productionStatisticsForAgencyFull";
import { fetchReadyToSellList } from "../store/actions/agents/readyToSell";
import { fetchAgentContractList } from "../store/actions/agents/agentsContractInfo";
import { fetchAgentReadyToSellInfo } from "../store/actions/agents/agentsReadyToSellInfo";
import { fetchAgentList } from "../store/actions/agents/agents";
import { fetchAgentReadyToSellByCarrier } from "../store/actions/agents/readyToSellByCarrier.js";
import {
  fetchProductionCarrierAndQtrData,
  fetchProductionSalesYear,
} from "../store/actions/production/productionLineGraph";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      textAlign: "center",
      margin: "0px",
      display: "grid",
      webkitBackgroundSize: "cover",
      mozBackgroundSize: "cover",
      backgroundSize: "16px 16px",
      minHeight: "100vh",
      background: "#424242",
      backgroundColor: "rgb(68, 66, 66)",
      paddingBottom: 20,
    },
    overlay: {
      height: "100%",
    },
  };
});

const LandingPage = () => {
  const [loaderText, setLoaderText] = useState("Configuring Agency...");
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState(
    useSelector(
      ({ productionStatisticsForAgency }) =>
        productionStatisticsForAgency.isLoading
    )
  );
  const navigate = useCallback((path) => {
    history.push(path);
  }, []);

  const LoadInitData = useCallback(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    const agentId = sessionStorage.getItem("agentId");
    setTimeout(async () => {
      setLoaderText("Gathering Data...");
      await Promise.all([
        dispatch(
          getProductionStatisticsForAgencyFullAction(parseInt(agencyId))
        ),
        dispatch(getAgency(agencyId, agentId)),
        dispatch(setETLDates()),
        dispatch(fetchAgentReadyToSellByCarrier(parseInt(agencyId))),
        dispatch(fetchProductionSalesYear()),
        dispatch(setPlanChange(agencyId, agentId)),
        dispatch(fetchAgentReadyToSellInfo(parseInt(agencyId))),
        dispatch(fetchAgentList(parseInt(agencyId))),
        dispatch(fetchAgentContractList(parseInt(agencyId))),
      ])
        .then(async () => {
          await Promise.all([
            dispatch(setProductionStatisticsForAgency(agencyId)),
            dispatch(fetchReadyToSellList(agencyId)),
          ]);
        })
        .then(async () => {
          await Promise.all([
            dispatch(fetchProductionCarrierAndQtrData(agencyId)),
          ]);
        })
        .then(() => {
          setIsDataLoaded(true);
        });
    }, 0);
    setTimeout(async () => setLoaderText("Collecting Agents..."), 2500);
    setTimeout(async () => setLoaderText("Producing Tables..."), 5000);
    setTimeout(async () => setLoaderText("Verifying Data..."), 7500);
    setTimeout(async () => setLoaderText("Loading Data..."), 10000);
    setTimeout(async () => setLoaderText("Still Loading..."), 15000);
    setTimeout(async () => setLoaderText("Still Loading......"), 20000);
    setTimeout(async () => setLoaderText("Still Loading........."), 25000);
    setTimeout(async () => setLoaderText("Still Loading............"), 30000);
    setTimeout(async () => setLoaderText("Still Loading........."), 35000);
    setTimeout(async () => setLoaderText("Still Loading..."), 40000);
    setTimeout(async () => setLoaderText("Almost There..."), 45000);
  }, [dispatch]);

  useEffect(() => {
    LoadInitData();
  }, [LoadInitData]);

  isDataLoaded && navigate("/");

  return (
    <LoadingOverlay
      active={!isDataLoaded}
      className={classes.overlay}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "100px",
        }),
      }}
      spinner
      text={<Typography style={{ fontSize: 20 }}>{`${loaderText}`}</Typography>}
    >
      <Grid
        className={classes.root}
        container
        spacing={0}
        direction="column"
        alignItems="center"
      />
    </LoadingOverlay>
  );
};

export default LandingPage;
