import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SelectAgencyGroups = (props) => {
  const { records, agencyId, setAgencyId } = props;
  const [checkedValue, setCheckedValue] = useState("");

  const handleChange = (event) => {
    const results = records.filter((item) => item.value === event.target.value);
    if (results.length > 0) {
      setCheckedValue(results[0].value.trim());
      setAgencyId(results[0].id);
    }
  };

  useEffect(() => {
    const matchedRecord = records.find((item) => item.id === agencyId);
    setCheckedValue(matchedRecord ? matchedRecord.value : "");
  }, [agencyId, records]);

  return (
    <Box>
      <FormControl sx={{ m: 1, width: 220, margin: 2 }}>
        {/* <InputLabel>Agency Group</InputLabel> */}
        <Select
          value={checkedValue}
          // label="Agency Group"
          onChange={handleChange}
          sx={{
            backgroundColor: "white",
          }}
        >
          {records.map((item) => (
            <MenuItem
              key={item.id}
              value={item.value}
              style={{ backgroundColor: "white" }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "yellow")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "white")}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectAgencyGroups;
