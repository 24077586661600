import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  ListSubheader,
  Drawer,
  Toolbar,
  Backdrop,
  ListItemAvatar,
  Avatar,
  Grid,
} from "@mui/material";
import {
  Business,
  Group,
  GroupAdd,
  HowToVoteOutlined,
  CallMade,
  ChevronRight,
  LanguageOutlined,
  DomainAdd
} from "@mui/icons-material";
import { Link, withRouter } from "react-router-dom";

import AgencyDetail from "./AgencyDetail";
import PageContainerWithMenu from "../../containers/PageContainerWithMenu";
import Reactour from "reactour";
import { clearAgentListFilterModel } from "../../store/actions/agents/agentsContractInfo";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import "./Home.css";
import HtmlToolTip from "../common/HtmlTooltip";
import ContractRequestModal from "../common/ContractRequestModal";
import CalcForm from "../calculator/CalcForm";
import IntegrityLogo from "./img/Integrity_Logo.svg";
import calculateIcon from "../../img/calculateIcon.png";
import pdfIcon from "../../img/pdf_icon.svg";
import BerwickB from "../../img/BerwickB.png";
import NIPR from "../../img/NIPR.png";
import ir from "../../img/ir.png";
import ce from "../../img/ce.png";

const drawerWidth = 250;

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      backgroundColor: "#9e9e9e",
      position: "relative",
    },
    paper: {
      bottomPadding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      background: "linear-gradient(to bottom right, white, #0e76bc) !important",
    },
    imageButton: {
      border: "none",
      background: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    input: {
      marginLeft: 8,
      flexGrow: 3,
      width: "10vw",
    },
    iconButton: {
      display: "inline-block",
      width: "auto",
      margin: 0,
      padding: 0,
      background: "none",
      backgroundColor: "transparent",
      border: "none",
      flexGrow: 1,
    },
    iconImage: {
      width: "50%",
    },
    buttonImage: {
      backgroundColor: "transparent",
      border: "none",
      paddingLeft: "2em",
      paddingRight: "2em",
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
    searchMenu: {
      [theme.breakpoints.down("sm")]: { margin: "auto" },
    },
    AgencyDashboard: {
      color: "#003760",
      fontSize: "5em",
      fontWeight: "bolder",
      width: "100%",
      "&:after": {
        content: '""',
        width: "100%",
        display: "inline-block",
      },
    },
    subHeader: {
      color: "#003760",
      fontSize: "1.5em",
    },
    drawer: {
      width: drawerWidth,
      zIndex: 0,
      [theme.breakpoints.down("md")]: { width: 0 },
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: 0,
      border: 0,
      background: "transparent",
      [theme.breakpoints.down("md")]: { width: 0 },
    },
    contentDiv: {
      width: "calc(100vw - 250px)",
      padding: "0px 35px 0px 5px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        padding: 0,
      },
    },
    listItem: {
      height: "100%",
      // background: "linear-gradient(-90, #214a76 50%, rgba(0,148,255,.25) 50%)",
      background: "rgba(33,74,118,1)",
      backdropFilter: "blur(2px)",
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
      "&:hover": {
        background: "rgba(33,74,118,.75)",
        boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.75)",
        backgroundPosition: "right",
        zIndex: 1,
      },
      transition: "all .25s ease",
    },
    resourceList: {
      left: 250,
      top: 64,
      padding: 0,
      position: "fixed",
      height: "calc(100% - 104px)",
      background: "white",
    },
    resourceListItem: {
      color: "#003760",
      borderBottom: "solid 2px #acc3d3 !important",
    },
    outerContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      width: "80%",
      margin: "auto",
      gap: 5,
    },
    innerContainer: {
      border: "1px solid #333",
      backgroundColor: "blue",
    },
    item: {
      margin: 2,
      padding: 10,
      borderRadius: 10,
      backgroundColor: "gray",
    },
    cardActions: {
      outline: "none",
      border: "none",
      textDecoration: "none",
      "& *": {
        color: "#333",
        textDecoration: "none",
      },
      "&:hover": {
        textDecoration: "none",
      },
      "&:focus": {
        outline: "none",
        border: "none",
        color: "yellow",
        textDecoration: "none",
      },
      "&:active": {
        outline: "none",
        border: "none",
        color: "green",
        textDecoration: "none",
      },
      "&:visited": {
        textDecoration: "none",
      },
    },
  };
});

const Home = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [tourIsOpen, setTourIsOpen] = useState(
    props.location.state ? props.location.state.tourIsOpen : false
  );
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [openResources, setOpenResources] = useState(false);
  const [resourceSelected, setResourceSelected] = useState(false);
  const [calcOpen, setCalcOpen] = useState(false);
  const recruiterCode = useSelector(
    (state) => state.recruiterStore.recruiterInfo.recruiterCode
  );
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const leadItem = {
    pathname: "/Leads",
    label: "My Leads",
    icon: <GroupAdd fontSize="large" style={{ color: "#FFFFFF" }} />,
  };

  const homeNavigation = [
    {
      pathname: "/AgentList",
      label: "My Agents",
      icon: <Group fontSize="large" style={{ color: "#FFFFFF" }} />,
      func: () => {
        dispatch(clearAgentListFilterModel());
      },
    },
    {
      pathname: "/Production",
      label: "Production Statistics",
      icon: <Business fontSize="large" style={{ color: "#FFFFFF" }} />,
    },
    {
      pathname: "/ProductionStatisticsSummary",
      label: "Production Statistics Summary",
      icon: <DomainAdd fontSize="large" style={{ color: "#FFFFFF" }} />,
    },
    {
      label: "Request New Contract",
      icon: <HowToVoteOutlined fontSize="large" style={{ color: "#FFFFFF" }} />,
      tooltipTitle: (
        <Typography variant="body1">
          Make an Agent Contract Request
          <br></br>
          <br></br>
          Questions? We are here to help! Call our agent support team at
          1-800-745-2320
        </Typography>
      ),
      func: () => {
        setContractModalOpen(true);
      },
    },
    {
      label: "Quoting and Resources",
      func: () => {
        setOpenResources(!openResources);
      },
    },
  ];

  const Tour = withRouter(
    ({
      onAfterOpen,
      onBeforeClose,
      isOpen,
      location: { pathname },
      history,
    }) => {
      const steps = [
        {
          selector: "",
          content: () => (
            <Typography style={{ paddingTop: 5 }}>
              Welcome to your Agency Overview!
            </Typography>
          ),
        },
        {
          selector: '[data-tour="ad__productionOverview"]',
          content:
            "Here you will find an overview of your agency's production.  This includes the Medicare Advantage and Medicare Supplement totals.  Additionally, it lists your top producing agent for each product!",
        },
        {
          selector: '[data-tour="ad__productionByCarrier"]',
          content:
            "This area breaks out your production by product for your top 3 carriers!",
        },
        {
          selector: '[data-tour="ad__agentInformation"]',
          content:
            "Here you will find a breakdown, including a pie chart, of your agent's contracting status.",
        },
        {
          selector: '[data-tour="ad__legend"]',
          content: () => (
            <div>
              Each chart has a very useful legend which can toggle data being
              shown. Try clicking Approved in the legend area{" "}
              <i>(not the pie chart)</i>.
            </div>
          ),
        },
        {
          selector: '[data-tour="ad__appbar_nav"]',
          content:
            "These buttons allow you to navigate to the other pages in the application.",
        },
        {
          selector: '[data-tour="ad__appbar_agents"]',
          content: "This button will take you to your Agents page.",
        },
        {
          selector: '[data-tour="ad__appbar_production"]',
          content: "This button will take you to your production page.",
        },
        {
          selector: '[data-tour="ad__appbar_contractrequest"]',
          content:
            "This button will open up a form where you can request contracts.",
        },
        {
          selector: '[data-tour="ad__appbar_clr"]',
          content: "This button will open up the Commission Loss Report page.",
        },
        {
          selector: '[data-tour="ad__appbar_refresh"]',
          content: "This button will refresh the data on the page.",
        },
        {
          selector: '[data-tour="ad__dashboardTitle"]',
          position: "top",
          content:
            "Here we have the Agency Dashboard title. If you ever want to return to the first landing page you saw, click here.",
        },
        {
          selector: '[data-tour="ad__appbar_logout"]',
          content:
            "Finally, you can log out of the application at any time here.",
        },
        {
          selector: "",
          content: "Continuing on the tour will take you to the Agents page.",
        },
        {
          selector: "",
          content: "",
          action: () =>
            history.push({
              pathname: "/agentList",
              state: {
                tourIsOpen: true,
              },
            }),
        },
      ];
      return (
        <Reactour
          onAfterOpen={onAfterOpen}
          onBeforeClose={onBeforeClose}
          steps={steps}
          isOpen={isOpen}
          onRequestClose={() => {
            setTourIsOpen(false);
            clearAllBodyScrollLocks();
          }}
          update={pathname}
          closeWithMask={false}
        />
      );
    }
  );

  const shouldShowLeads = recruiterCode;
  const recruiterArr = [...homeNavigation];
  recruiterArr.splice(1, 0, leadItem);
  const mainNav = shouldShowLeads ? recruiterArr : homeNavigation;
  const outsideRightIcons = (
    <>
      <LanguageOutlined
        style={{
          fontSize: "1.75em !important",
          color: "inherit",
        }}
      />
      <CallMade
        style={{
          fontSize: "1.75em !important",
          color: "inherit",
        }}
      />
    </>
  );

  const innerRightIcon = (
    <ChevronRight
      style={{
        color: "inherit",
      }}
    />
  );

  const resourceMap = [
    {
      title: "MedicareCENTER",
      message: "Your online CRM, quoting, and enrollment tool",
      href: "https://www.medicarecenter.com/welcome",
      icon: IntegrityLogo,
      rightIcon: outsideRightIcons,
    },
    {
      title: "PDP Penalty Calculator",
      message: "Calculate Part D penalty amount",
      func: () => {
        setCalcOpen(!calcOpen);
      },
      icon: calculateIcon,
      rightIcon: innerRightIcon,
    },
    {
      title: "IRMAA",
      message: "Income-related monthly adjustment amount",
      href: "https://www.medicare.gov/drug-coverage-part-d/costs-for-medicare-drug-coverage/monthly-premium-for-drug-plans",
      icon: ir,
      rightIcon: outsideRightIcons,
    },
    {
      title: "LIS Income Chart",
      message: "View LIS Income Chart",
      href: "CY2024 LIS Resource Limits Memo_508.pdf",
      icon: pdfIcon,
      rightIcon: innerRightIcon,
    },
    {
      title: "Berwick Insurance Agent Portal",
      message:
        "Your Portal for Creating Successful Partnerships One Agent at a Time!",
      href: "https://www.berwickinsurance.com/",
      icon: BerwickB,
      rightIcon: outsideRightIcons,
    },
    {
      title: "E&O Insurance",
      message: "Berwick Insurance sponsored exclusive E&O coverage!",
      href: "https://www.calsurance.com/berins",
      icon: BerwickB,
      rightIcon: outsideRightIcons,
    },
    {
      title: "NIPR",
      message: "Go to National Insurance Producer Registry",
      href: "https://nipr.com/",
      icon: NIPR,
      rightIcon: outsideRightIcons,
    },
    {
      title: "License and CE",
      message: "Insurance, Securities, and Continuing Education Training",
      href: "ExamFX Registration Guide - Berwick Insurance Group.pdf",
      icon: ce,
      rightIcon: innerRightIcon,
    },
  ];

  return (
    <div>
      <PageContainerWithMenu
        data-tour="ad__home"
        type={"home"}
        HasHomeButton={false}
        HasMenu={false}
        content={
          <div className={classes.root}>
            <Drawer
              variant="permanent"
              className={classes.drawer}
              classes={{ paper: classes.drawerPaper }}
            >
              <Toolbar />
              <List
                style={{
                  paddingBottom: 0,
                  height: "100%",
                }}
                subheader={
                  <ListSubheader
                    style={{ background: "#424242", color: "#eee" }}
                    component="div"
                    aling="center"
                  >
                    Quick Nav
                  </ListSubheader>
                }
              >
                <div style={{ height: "calc(100% - 85px)" }}>
                  {mainNav.map(
                    ({ pathname, label, tooltipTitle, icon, func }, i) => (
                      <div
                        key={`${label}-${i}`}
                        style={{
                          height: `calc(100% / ${mainNav.length})`,
                        }}
                      >
                        <Link
                          to={pathname ?? ""}
                          onClick={func ?? void 0}
                          style={{
                            textDecoration: "none",
                            position: "relative",
                          }}
                        >
                          <HtmlToolTip title={tooltipTitle ?? ""}>
                            <ListItemButton
                              divider
                              className={classes.listItem}
                            >
                              {icon ? (
                                <ListItemIcon>{icon}</ListItemIcon>
                              ) : null}
                              <ListItemText
                                align="center"
                                primary={label}
                                primaryTypographyProps={{
                                  style: {
                                    fontSize: "1.3em",
                                  },
                                }}
                                style={{
                                  color: "#eee",
                                }}
                              />
                              {label === "Quoting and Resources" && (
                                <ChevronRight
                                  style={{
                                    color: "#eee",
                                    position: "absolute",
                                    top: "50%",
                                    right: -6,
                                    transform: "translate(-50%,-50%)",
                                  }}
                                />
                              )}
                            </ListItemButton>
                          </HtmlToolTip>
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </List>
            </Drawer>
            <Backdrop
              style={{ zIndex: 1 }}
              open={openResources}
              onClick={() => {
                setOpenResources(!openResources);
              }}
            >
              <List className={classes.resourceList} component="nav">
                {resourceMap.map(
                  ({ title, message, func, icon, href, rightIcon, alt }, i) => {
                    return (
                      <ListItemButton
                        key={`${title}-${i}`}
                        className={classes.resourceListItem}
                        divider
                        component="a"
                        selected={resourceSelected === i}
                        style={{
                          height: `calc(100% / ${resourceMap.length})`,
                          position: "relative",
                          color: "#003760",
                          outline: "none",
                        }}
                        onClick={(e) => (func ? func(e) : undefined)}
                        href={href}
                        target="_blank"
                        rel="noreferrer noopener"
                        onMouseEnter={() => setResourceSelected(i)}
                        onMouseLeave={() => setResourceSelected(i)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sizes="small"
                            variant="square"
                            alt={alt}
                            src={icon}
                            style={{ height: 32, width: 32 }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={title}
                          secondary={message}
                          primaryTypographyProps={{
                            style: { fontWeight: "600" },
                          }}
                          secondaryTypographyProps={{
                            style: { color: "inherit" },
                          }}
                        />
                        <Grid
                          style={{
                            opacity: resourceSelected === i ? 1 : 0,
                            transition: "opacity .5s",
                          }}
                          item
                          xs={2}
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {rightIcon}
                        </Grid>
                      </ListItemButton>
                    );
                  }
                )}
              </List>
            </Backdrop>
            <div>
              <AgencyDetail />
            </div>
          </div>
        }
      />
      <Tour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        isOpen={tourIsOpen}
        rounded={5}
        accentColor={"white"}
      />
      <ContractRequestModal
        contractModalOpen={contractModalOpen}
        handleClose={() => {
          setContractModalOpen(false);
        }}
      />
      <CalcForm
        open={calcOpen}
        handleClose={() => {
          setCalcOpen(!calcOpen);
        }}
      />
    </div>
  );
};

export default Home;
